<template>
  <div class="page">
    <div class="contacts">
      <h1 class="title main-title">
        Контакты
      </h1>
      <div class="content">
        <p>
          Два офиса, в Москве и Санкт-Петербурге:
        </p>
        <ul class="list">
          <li>
            г. Москва, вн.тер.г. муниципальный округ Северный, ш Долгопрудненское, д. 3, помещение XI, ком. №13 (21 м кв);
          </li>
        </ul>
        <br>
        <div class="imgWrapper">
          <img src="@/assets/img/landing/contacts/office_msk.png" />
        </div>
        <ul class="list">
          <li>
            г. Санкт-Петербург, ул. Большая Зеленина дом 24 стр. 1, 8 этаж офис 210-Н (323 м кв).
          </li>
        </ul>
      </div>
      <h1 class="title">
        Реквизиты
      </h1>
      <div class="content">
        <p>
          Общество с ограниченной ответственностью «Интернет решения»<br>
          ОРН Сколково 1122878<br>
          Юридический адрес:<br>
          127495, г.Москва, вн.тер.г. муниципальный округ Северный,<br>
          ш Долгопрудненское, д. 3, помещение XI, ком. №13<br>
          Почтовый адрес обособленного подразделения:<br>
          197110, г. Санкт-Петербург, ул Большая Зеленина дом 24 стр 1, 8 этаж офис 210-Н<br>
          (812) 240-13-58<br>
          ИНН 7810970909<br>
          КПП 771501001<br>
          ОГРН 1147847322754<br>
          ОКАТО 45280585000<br>
          ОКПО 31973334<br>
          Дата регистрации 19.09.2014<br>
          ОКВЭД 62.01<br>
          ОКТМО 45363000000<br>
          Филиал "Корпоративный" ПАО "Совкомбанк"<br>
          к/с 30101810445250000360 в ГУ Банка России по ЦФО БИК 044525360<br>
          ИНН 4401116480<br>
          КПП 770343003<br>
          ОГРН 1144400000425<br>
          Адрес: 119991, г. Москва, ул. Вавилова, д.24<br>
          расчетный счет: 40702810612010504862<br>
          Генеральный директор/главный бухгалтер<br>
          Поломарь Станислав Юрьевич<br>
          Номер записи реестра: 15894<br>
          Дата записи реестра: 09.12.2022<br>
          Дата решения уполномоченного органа: 09.12.2022<br><br>

          Страница карточки соответствующей записи реестра:
          <a href="https://reestr.digital.gov.ru/reestr/1199166/" target="_blank">https://reestr.digital.gov.ru/reestr/1199166/</a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contacts',

  metaInfo() {
    return {
      title: 'Контакты DATAFORCE: адрес офиса, реквизиты компании',
      meta: [
        {
          name: 'description',
          content: 'DATAFORCE — Информация о компании, переходите на сайт или свяжитесь с менеджером для получения подробной информации'
        }
      ]
    }
  }
}
</script>

<style
    scoped
    lang="scss"
    src="./contacts.scss"
/>
